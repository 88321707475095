// Body
$body-bg: #fff;

// Colors
$white: #FFFFFF;
$green: #2BADBD;
$lightblue: #81C7F1;
$naviblue: #0058A2;
$darkblue: #07285B;
$darkblue-light: #07285b9e;
$primary-gradient: linear-gradient(45deg, #1169AF, #27AAB6);
$darkblue-opacity: #07285b61;
$lighting-color: #FAFFFD;
$grey: #77859b;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

///else
$transition: .3s ease-out;

// apply fonts
body{
	font-family: 'Poppins', sans-serif;
}
// header
header{
	padding: 1rem 1.75rem;
	@include media-breakpoint-down(sm) {
		padding: .625rem 1.375rem;
		background: $white;
		box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
		position: relative;
    	bottom: 3.95rem;
    	height: 3.95rem;
	}
	width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;
}
header.logged{
	display: flex;
	justify-content: space-between;
	position: fixed;
    background: $white;
    @include media-breakpoint-down(sm) {
    	top: 0;
    	height: 3.95rem;
    	margin-top: 0;
    }
	& .navbar{
		@include media-breakpoint-up(xl){
			width: 50%;
	    	margin-right: 7rem;
	    	min-width: 47rem;
	    }
    	@include media-breakpoint-down(sm) {
    		margin: 0;
    		min-width: auto;
    	}
	}
	& .nav-ul-wrapper{
		width: 100%;
	}
	@include media-breakpoint-down(xl){
		& .nav-ul-wrapper{
			background: #07285b94;
		    position: fixed;
		    left: 0;
		    top: 0;
		    height: 100%;
		    display: none;
		}
		& .nav-ul-wrapper.sm-show{
			display: block;
		}
	}
	& .nav-ul-wrapper > ul{
		list-style: none;
	    text-transform: uppercase;
	    display: flex;
	    justify-content: space-between;
	    font-size: 0.875rem;
	    width: 100%;
	    font-weight: 500;
	    margin-bottom: 0;
	    @include media-breakpoint-down(sm) {
	    	flex-wrap: wrap;
	    	display: none;
	    }
	    @include media-breakpoint-between(sm, xl) {
	    	flex-wrap: wrap;
	    	display: none;
	    }
	}

	@include media-breakpoint-down(sm) {
		& .nav-ul-wrapper.sm-show > ul{
			width: 90% !important;
		    height: 100%;
		    right: 0;
		    top: 0;
		    position: fixed;
		    z-index: 1;
		    background: $darkblue;
		    text-align: center;
		    padding: 0;
		    padding-top: 4.5rem;
		    display: block;
		    & li{
		    	margin-bottom: 0.75rem;
		    }
		    & li a{
		    	color: $white;
		    	font-size: 0.9375rem;
		    }
		}
		button#navbar-close.show{
			display: block;
			position: absolute;
		    right: 0;
		    top: 15px;
		    background: transparent;
		    border: transparent;
		    z-index: 2;
			&:after{
				content: url('/assets/icons/xmark.svg');
			}
		}
	}

	@include media-breakpoint-between(sm, xl) {
		& .nav-ul-wrapper.sm-show > ul{
			width: 65% !important;
		    height: 100%;
		    right: 0;
		    top: 0;
		    position: fixed;
		    z-index: 1;
		    background: $darkblue;
		    text-align: center;
		    padding: 0;
		    padding-top: 4.5rem;
		    display: block;
		    & li{
		    	margin-bottom: 1rem;
		    }
		    & li a{
		    	color: $white;
		    	font-size: 1.25rem;
		    }
		}
		button#navbar-close.show{
			display: block;
			position: absolute;
		    right: 0;
		    top: 15px;
		    background: transparent;
		    border: transparent;
		    z-index: 2;
			&:after{
				content: url('/assets/icons/xmark.svg');
			}
		}
	}

	& li.active > a {
		color: $green;
	}
	a{
		color: $darkblue;
		font-weight: 600;
	}
}
button#navbar-close, button#navbar-open{
	display: none;
}
@include media-breakpoint-down(sm) {
	button#navbar-open{
		display: block;
		position: absolute;
	    right: -5px;
    	top: 10px;
	    background: transparent;
	    border: transparent;
		&:after{
			content: url('/assets/icons/hamburger.svg');
		}
	}
}
@include media-breakpoint-between(sm, xl) {
	button#navbar-open{
		display: block;
		position: absolute;
	    right: -5px;
    	top: 10px;
	    background: transparent;
	    border: transparent;
		&:after{
			content: url('/assets/icons/hamburger-xl.svg');
		}
	}
}

.header-spacer{
	display: block;
    height: 95px;
    @include media-breakpoint-down(sm) {
    	display: none;
    }
}
header .logo{
	@include media-breakpoint-down(sm) {
		width: 8.75rem;
	    position: relative;
	    bottom: 0.75rem;
	}
	& img{
		max-width: 100%;
	}
}
.sm-card-wrapper{
	@include media-breakpoint-down(sm) {
		padding: 0;
	}
}
.without-bg-wrapper{
	@include media-breakpoint-down(sm) {
		margin-top: 3.95rem;
	}
}
// body
.bg-main{
	background-image: url('/assets/images/main-photo.jpg');
    background-size: auto;
    @include media-breakpoint-down(sm) {
    	background-size: contain;
    	margin-top: 3.95rem;
    }
    background-repeat: no-repeat;
}
@include media-breakpoint-up(sm){
	.bg-main.bg-cover-sm{
		background-size: cover;
	}
}
@include media-breakpoint-between(sm, xl){
	.bg-pos-x-sm-xl--17rem{
		background-position-x: -17rem;
	}
}
.bg-second-hero{
	background-image: url('/assets/images/second-hero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-kontakt-hero{
	background-image: url('/assets/images/contact-hero.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-blue{
	background: #5f7291;
}
.bg-category-case{
	background-image: url('/assets/images/category-case.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-category-library{
	background-image: url('/assets/images/category-library.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.f-screen{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
}
.centered-flex{
	display: flex;
	justify-content: center;
	align-items: center;
}
.vh-80{
	min-height: 80vh;
	@include media-breakpoint-between(sm, lg) {
		min-height: 75vh;
	}
    @include media-breakpoint-between(lg, xl) {
		min-height: 85vh;
	}
	@include media-breakpoint-between(xl, xxl) {
		min-height: 100vh;
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: 0;
		min-height: 50vh;
	}
}
.login-card{
	background: $white;
	padding: 40px 50px;
	width: 490px;
    height: 550px;
    border-radius: .5rem;
    color: $darkblue;
    max-width: 100%;
    @include media-breakpoint-down(sm){
    	border-radius: 0;
    	margin-top: 5rem;
    	padding: 40px 20px;
    }
    & label{
    	font-size: .875rem;
    	color: $darkblue-light;
    	width: 100%;
    }
}
.r-card{
	background: $white;
	padding: 40px 50px;
	width: 680px;
    min-height: 570px;
    border-radius: .5rem;
    color: $darkblue;
    @include media-breakpoint-between(sm, xl) {
    	width: 560px;
    	padding: 40px;
    }
    & label{
    	font-size: .875rem;
    	color: $darkblue-light;
    	width: 100%;
    	@include media-breakpoint-between(sm, xl) {
    		font-size: .75rem;
    	}
    }
    @include media-breakpoint-down(sm) {
	    border-radius: 0;
	    margin-top: 5rem;
	    padding: 40px 20px;
	    max-width: 100%;
	}
}
@include media-breakpoint-between(sm, xl) {
	.form-control, .form-select{
		font-size: .875rem;
	}
}
.login-card, .r-card{
	h2{
		font-size: 29px;
		@include media-breakpoint-down(sm) {
			font-size: 1.75rem;
			font-weight: 500;
		}
		@include media-breakpoint-between(sm, xl) {
			font-size: 1.5rem;
			font-weight: 500;
		}
	}
	& .lead-text{
		&:after{
			width: 55px;
		}
	}
}
.lead-text{
	&:after{
		content: '';
		display: block;
		height: 5px;
	    width: 75px;
	    background: $green;
	    margin-top: 20px;
	}
}
.lead-text.text-center{
	&:after{
		margin: auto;
		margin-top: 20px;
	}
}
.lead-text.fs-35{
	&:after{
		width: 110px;
	}
}
.form-control{
	padding: 10px 14px;
}
.form-control:focus{
	border: 1px solid $darkblue;
	box-shadow: none;
}
.btn{
	font-size: .875rem;
	border-radius: 2rem;
	text-transform: uppercase;
	font-weight: 600;
	transition: $transition;
	padding: 0.915rem 2rem;
	letter-spacing: 1px;
}
.btn-primary{
	background-image: $primary-gradient;
    border: none;
}
.btn-outline-primary{
	color: $green;
    border-color: $green;
    &:hover{
    	color: $green;
    	background-color: $white;
    	border-color: $green;
    }
}
.btn-darkblue{
	background: $darkblue;
    border: none;
    color: $white;
    &:hover{
    	color: $white;
    }
}
.btn-outline-darkblue{
	color: $darkblue;
    border-color: $darkblue;
    &:hover{
    	color: $darkblue;
    	background-color: $white;
    	border-color: $darkblue;
    }
}
span.line{
	text-align: center;
    width: 100%;
    display: inline-flex;
    font-size: .875rem;
    color: $darkblue-opacity;
    text-transform: uppercase;
    & > .text{
    	position: relative;
	    bottom: 10px;
	    margin: 0 3%;
	    @include media-breakpoint-down(sm) {
	    	font-size: .75rem;
	    }
    }
    &:before, &:after{
    	content: '';
    	display: block;
	    height: 1px;
	    width: 30%;
	    background: $darkblue-opacity;
    }
}
.register-bottom{
	font-size: 0.875rem;
	font-weight: 400;
}
a{
	text-decoration: none;
	color: $green;
}
.form-check{
	& label{
		font-size: 0.6875rem;
		position: relative;
    	top: 4px;
    	color: $darkblue;
    	&.top-0{
    		top: 0px;
    	}
	}
	& p{
		font-size: 0.75rem;
	}
}
.p-large{
	padding: 90px;
	@include media-breakpoint-down(sm) {
		padding: 0;
	}
}
.btn-default{
  background: linear-gradient(45deg, #126BAF, #2BADBD);
  padding: 10px 45px;
  font-size: 16px;
  color: #fff;
  border-radius: 3rem;
  text-transform: uppercase;
  &:hover{
    color: #fff;
  }
}
.card{
	background: $white;
	padding: 40px 20px 20px 20px;
    border-radius: .5rem;
    color: $darkblue;
    max-width: 560px;
    & label{
    	font-size: .875rem;
    	color: $darkblue;
    	width: 100%;
    }
    & h2{
    	font-size: 1.25rem;
    	color: $darkblue;
    }
    & input {
    	color: $darkblue;
    	&:disabled{
    		background: none;
    	}
    }
    &.card-full{
    	max-width: 100%;
    }
}
.text-green{
	color: $green;
}
.input-flex{
	border-bottom: 1px solid #07285b38;
    padding-bottom: 1rem;
    padding-left: 30px;
    padding-right: 30px;
	label{
		color: $darkblue-opacity;
	}
	.flash-message{
		margin-left: 1rem;
	    font-size: x-small;
	    font-weight: 700;
		&.success{
			color: $green;
		}
		&.error{
			color: red;
		}
	}
	input{
		border: none;
    	padding: 0;
    	font-weight: 500;
    	&:focus{
    		border: none;
    	}
	}
}
.flash-message{
	margin-left: 1rem;
    font-size: x-small;
    font-weight: 700;
	&.success{
		color: $green;
	}
}
.link-flex{
	border-bottom: 1px solid #07285b38;
    padding-bottom: 1rem;
    padding-left: 30px;
    padding-right: 30px;
    & span{
    	font-weight: 500;
    	color: $darkblue;
    }
}
////after login
.hero{
	& h2{
		font-size: 2.625rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.75rem;
			font-weight: 600;
		}
		@include media-breakpoint-between(sm, xl) {
			font-size: 1.75rem;
		}
	}
	& p{
		font-size: 1.125rem;
		font-weight: 400;
		@include media-breakpoint-down(sm) {
			font-size: 1rem;
		}
		@include media-breakpoint-between(sm, xl) {
			font-size: 1rem;
		}
	}
}
.row.categories{
    width: 860px;
    margin: auto;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
    	width: 100%;
    	justify-content: center;
    }
    @include media-breakpoint-between(sm, lg) {
    	width: auto;
    	justify-content: center;
    }
    & > .card-category{
        max-width: 33%;
        @include media-breakpoint-down(sm) {
	        max-width: 100%;
	    }
	    @include media-breakpoint-between(sm, lg) {
	    	max-width: 80%;
	    	margin-right: 10px;
	    }
        width: 270px;
        height: 270px;
        box-shadow: 0 0.5rem 1rem rgb(0, 0, 0,.15);
        text-align: center;
        padding: 35px;
        margin-bottom: 2rem;
        & > h3{
            font-size: 20px;
            font-weight: 600;
            margin-top: 25px;
            margin-bottom: 25px;
            color: $darkblue;
        }
        & > a.btn{
            font-size: 14px;
            padding: 20px 45px;
        }
    }
}
.bg-light{
	background: $lighting-color;
}
h2.fs-35{
	font-size: 2.1875rem;
	@include media-breakpoint-down(sm) {
		font-size: 1.75rem;
	}
}
.vertically-centered{
	display: flex;
    flex-flow: column;
    justify-content: center;
    @include media-breakpoint-down(sm) {
	    &.sm-vetrically-end{
	    	justify-content: end;
	    }
	}
}
.mt--5rem{
	margin-top: -5rem;
}
.p-1-3-rem{
	padding: 1rem 3rem;
}
@include media-breakpoint-down(sm) {
	.mt-xs-22rem{
		margin-top: 22rem !important;
	}
	.mt-xs-2rem{
		margin-top: 2rem;
	}
	.pt-xs-4rem{
		padding-top: 4rem;
	}
	.mb-xs-0{
		margin-bottom: 0 !important;
	}
	.mt-xs-4rem{
		margin-top: 4rem !important;
	}
	.p-xs-0{
		padding: 0;
	}
	.br-xs-0{
		border-radius: 0;
	}
	.mw-xs-220px{
		max-width: 220px;
	}
	.bg-contain-xs{
		background-size: contain;
	}
	.mt-xs-7rem{
		margin-top: 7rem;
	}
	.xs-img-mw-90{
		max-width: 90%;
    	height: auto;
	}
	.m-xs-2{
		margin: .5rem;
	}
	.p-xs-1rem{
		padding: 1rem;
	}
	.px-xs-0{
		padding-left: 0;
    	padding-right: 0;
	}
	.pt-xs-10rem{
		padding-top: 10rem !important;
	}
	.pb-xs-5rem{
		padding-bottom: 5rem;
	}
}
@include media-breakpoint-down(lg){
	.lg-flex-column-reverse{
		flex-flow: column-reverse;
	}
}
@include media-breakpoint-between(sm, xl) {
	.mt-sm-xl-1rem{
		margin-top: 1rem !important;
	}
	.mb-sm-xl-2rem{
		margin-bottom: 2rem;
	}
	.mt-sm-xl-5rem{
		margin-top: 5rem !important;
	}
}
i.arrow-right:after{
	content: url('/assets/icons/arrow-right.svg');
}
i.arrow-left:after{
	content: url('/assets/icons/arrow-left.svg');
}
i.arrow-left-default:after{
	content: url('/assets/icons/arrow-left-default.svg');
}
.drop-shadow{
	border: 8px solid #2badbd;
	width: 46.5rem;
	height: 24.9rem;
	position: absolute;
	left: 60px;
	bottom: 60px;
	@include media-breakpoint-down(sm) {
		top: 22px;
	    width: 19.5rem;
	    height: 10.5rem;
	    left: 35px;
	}
	@media (min-width: 1401px) and (max-width: 1599.98px) {
		width: 38.5rem;
    	height: 20.9rem;
	}
}
.with-shadow{
	position: inherit;
	@include media-breakpoint-between(sm, lg) {
		width: 500px;
	}
	@include media-breakpoint-between(lg, xxl) {
		width: 530px;
	}
	@media (min-width: 1401px) and (max-width: 1599.98px) {
		width: 620px;
	}
}
@include media-breakpoint-between(sm, xxl) {
	.drop-shadow{
		max-width: 90%;
    	width: 33rem;
    	height: 18rem;
    	left: 4rem;
    	bottom: 2.5rem;
	}
}
.container{
	& h2, & p{
        color: $darkblue;
    }
    @include media-breakpoint-down(sm) {
    	& h2{
    		font-size: 1.625rem;
    	}
    }
}
@include media-breakpoint-down(sm) {
	#my-account-card{
		padding-bottom: 10px;
    	padding-left: 0;
    	padding-right: 0;
    	& > .link-flex:nth-of-type(4), & > .link-flex:nth-of-type(5), & > .link-flex:nth-of-type(6){
    		padding-bottom: 30px;
    		padding-top: 15px;
    	}
    	& > .link-flex:last-of-type{
    		border-bottom: 0;
    		padding-bottom: 10px;
    	}
	}
}
@include media-breakpoint-between(sm, xxl) {
	#my-account-card{
		.input-flex, .input-flex label, .input-flex input{
			font-size: 1rem;
		}
	}
}
// footer
footer{
	background: $darkblue;
	height: 110px;
	padding: 40px 0 40px 0px;
	@include media-breakpoint-down(sm) {
		height: auto;
		& > .d-flex{
			flex-flow: column;
    		text-align: center;
		}
		& .logo{
			margin-bottom: 1rem;
    		margin-top: 1rem;
		}
	}
	& ul li{
		text-transform: uppercase;
		list-style: none;
		margin: 0 40px;
		& a{
			text-decoration: none;
			color: $white;
			transition: $transition;
			letter-spacing: 1px;
			font-weight: 600;
			&:hover{
				color: $white;
				text-decoration: underline;
			}
		}
		font-size: .75rem;
		font-weight: bold;
	}
}
.bottom-menu{
    justify-content: end;
    flex-flow: column;
    @include media-breakpoint-down(sm) {
    	& ul{
    		flex-wrap: wrap;
		    justify-content: center;
		    padding: 0;
		    & > li{
		    	margin-bottom: 1rem;
    			width: 100%;
		    }
    	}
    }
}
.heading-darkblue{
	color: $darkblue;
}
#contact-card{
	& .form-check label{
		font-size: 0.625rem;
	}
}
.btn-sm{
	font-size: .75rem;
    padding: 0.2rem 0.4rem;
    text-transform: none;
}
.grey-text-sm{
	text-transform: uppercase;
    color: $grey;
    font-size: .875rem;
    font-weight: 600;
    & a{
    	color: $grey;
    }
}
a:hover{
	color: inherit;
}
.cart-subcat{
    width: 326px;
    height: 192px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    & span.number{
        font-size: 100px;
        font-weight: 800;
        max-height: 25px;
        opacity: .15;
        margin-left: 5px;
        font-family: 'Open Sans', sans-serif;
    }
    & h4{
        font-size: 20px;
        font-weight: 600;
        margin-left: 20px;
    }
}
.container.admin{
	& form{
		text-align: right;
	}
}
span.show-hide-password{
	position: absolute;
	bottom: 5px;
    right: 25px;
	cursor: pointer;
	&:after{
		content: url('/assets/icons/eye-slash.svg');
	}
	&.password-hidden:after{
		content: url('/assets/icons/eye.svg');
		position: relative;
    	top: -3px;
	}
	&.r-15px{
		right: 15px;
	}
}
textarea, input, select, .form-control,  .form-control:focus{
	color: $darkblue;
}
.partners{
	background: $white;
	font-size: 1rem;
	padding: 2rem;
	padding-bottom: .5rem;
	color: #07285bb3;
	& p {
		letter-spacing: 1px;
	}
	& img{
		max-height: 110px;
	}
}
.fw-bold, b, strong{
	font-weight: 600 !important;
}
#reset-password-link{
	cursor: pointer;
	color: $darkblue;
	font-size: 1rem;
}
.cookies-wrapper{
	position: relative;
	display: none;
	&.show{
		display: block;
		position: fixed;
	    bottom: 0;
	    width: 100%;
	}
}
.cookies{
	font-size: .875rem;
	bottom: 10px;
    position: absolute;
    height: 100px;
    width: 100%;
    background: $white;
    @include media-breakpoint-down(sm) {
		height: auto;
	    padding: 10px;
	}
}
@include media-breakpoint-up(xxl) {
	.container-xxl{
		max-width: 1610px;
	}
}
@include media-breakpoint-down(sm) {
	.cookies-btns{
		& > a, & > button{
			font-size: .65rem;
	    	margin: 5px 0;
		}
	}
}
@include media-breakpoint-up(xl) {
	.cookies-btns{
		min-width: 320px;
	}
}
.content {
	color: $darkblue;
}

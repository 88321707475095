//summernote
.note-editor.note-frame.card{
	padding: 0;
	max-width: 100%;
	border-radius: 4px;
}
.note-btn-group .note-btn{
	border-radius: 0.2rem;
}
//migrator to bootstrap 5
.note-editor button{
	font-weight: 400;
    background-image: none;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
}
.open > .dropdown-menu {
	display: block;
	top: 32px;
}
.note-editor button.active, .btn-group.open > button{
	background-color: #d3d3d3;
    border-color: #adadad;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}
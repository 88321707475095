.modal-dialog{
	max-width: 584px;
}
.modal-content{
	padding: 60px 80px;
	color: $darkblue;
	@include media-breakpoint-down(sm) {
		padding: 25px;
	}
	& h5{
		font-size: 2.1875rem;
	}
	& p{
		font-size: 1.125rem;
	}
}
.modal-header{
	border-bottom: none;
}
.modal-title{
	font-weight: 600;
	font-size: 2.1875rem;
}
.modal-footer{
	border-top: none;
	justify-content: center;
	& button, & a{
		width: 45%;
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
}
.modal.show{
	background: #07285b94;
}
@include media-breakpoint-down(sm) {
	.modal-content img{
		width: 62px;
	}
}